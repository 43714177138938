<template>
  <div class="login-page">
    <div class="container-xl">
      <section class="row align-items-center vh-100">
        <div
          class="login-form col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-6 col-xl-4 offset-xl-8"
        >
          <div class="d-flex justify-content-between">
            <router-link to="/main/herbarium/grid">
              <img
                src="../assets/icon/icon-herbarium.svg"
                alt="logo-herbarium"
                class="logo mt-2 mt-md-4"
              />
            </router-link>
            <LanguageSwitcher class="align-self-center mt-3" />
          </div>

          <h2 class="my-4">{{ $t('login_page_title') }}</h2>
          <div class="social-block">
            <b-button
              block
              variant="link"
              :disabled="isLoading"
              @click="signInGoogle()"
            >
              <img
                src="../assets/icon/icon-google.svg"
                alt="logo-google"
                class="mr-1"
              />
              {{ $t('login_page_btn_sign_google') }}
            </b-button>
            <b-button
              block
              variant="link"
              :disabled="isLoading"
              @click="signInFacebook()"
            >
              <img
                src="../assets/icon/icon-fb.svg"
                alt="logo-fb"
                class="mr-1"
              />
              {{ $t('login_page_btn_sign_facebook') }}
            </b-button>
          </div>
          <p class="my-3 text-center or">{{ $t('login_page_text_or') }}</p>
          <b-form class="login-page-form" @submit.prevent="loginUser()">
            <b-form-group
              :label="$t('login_page_input_email_label')"
              label-for="email"
              role="group"
            >
              <b-form-input
                id="email"
                v-model.trim="$v.user.email.$model"
                :state="validateState('email')"
              />
              <template v-if="$v.user.email.$dirty && $v.user.email.$invalid">
                <b-form-text v-if="!$v.user.email.email" text-variant="danger">
                  {{ $t('login_page_input_email_error_valid') }}
                </b-form-text>
                <b-form-text
                  v-if="!$v.user.email.required"
                  text-variant="danger"
                >
                  {{ $t('profile_info_confirm_password_required_error') }}
                </b-form-text>
              </template>
            </b-form-group>
            <b-form-group
              :label="$t('login_page_input_password_label')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model.trim="$v.user.password.$model"
                type="password"
                :state="validateState('password')"
              />
              <template
                v-if="$v.user.password.$dirty && $v.user.password.$invalid"
              >
                <b-form-text
                  v-if="!$v.user.password.required"
                  text-variant="danger"
                >
                  {{ $t('profile_info_confirm_password_required_error') }}
                </b-form-text>
              </template>
            </b-form-group>
            <b-button
              type="submit"
              variant="success"
              block
              class="mt-2 submit"
              :disabled="isLoading"
            >
              <b-spinner v-if="isLoading" show small type="grow" />
              {{ $t('login_page_btn_login') }}
            </b-button>
          </b-form>
          <p class="text-center my-3">
            <router-link to="/password/recovery" class="text-success">
              {{ $t('login_page_link_forgot_pwd') }}
            </router-link>
          </p>
          <p class="register text-center mb-lg-3">
            {{ $t('login_page_text_not_account') }}
            <router-link to="/register" class="text-success">
              {{ $t('login_page_link_not_register') }}
            </router-link>
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import config from '../config'
import LanguageSwitcher from '@/components/LanguageSwitcher'

export default {
  components: { LanguageSwitcher },
  data() {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
    user: {
      email: { required, email },
      password: { required }
    }
  },
  computed: mapGetters(['errorAuth', 'getResponse', 'isLoading']),
  mounted() {
    this.clearError()
    window.gapi.load('auth2', function() {
      window.gapi.auth2
        .init({
          client_id: config.GOOGLE.client_id
        })
        .then(
          () => console.log('Init Ok'), // eslint-disable-line no-console
          err => console.log('Init fail', err) // eslint-disable-line no-console
        )
    })

    window.FB.init({
      appId: config.FACEBOOK.appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v6.0'
    })
  },
  methods: {
    ...mapActions(['clearError']),
    loginUser() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$store
        .dispatch('loginUser', { path: '/auth/login', user: this.user })
        .then(() => {
          if (!this.errorAuth) {
            this.$router.push('/main/herbarium/grid').catch(() => {})
          }
        })
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.user[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    },
    async signInGoogle() {
      const GoogleAuth = window.gapi.auth2.getAuthInstance()
      const gUser = await GoogleAuth.signIn({ scope: 'profile email' })
      const user = {
        email: gUser.getBasicProfile().getEmail(),
        id: gUser.getBasicProfile().getId(),
        name: gUser.getBasicProfile().getName()
      }
      this.$store
        .dispatch('loginUser', { path: '/auth/google', user })
        .then(() => {
          if (!this.errorAuth) {
            this.$router.push('/main/herbarium/grid').catch(() => {})
          }
        })
    },
    signInFacebook() {
      const saveUser = user => {
        this.$store
          .dispatch('loginUser', { path: '/auth/facebook', user })
          .then(() => {
            if (!this.errorAuth) {
              this.$router.push('/main/herbarium/grid').catch(() => {})
            }
          })
      }

      //Вылетает ворнинг который ругается на токен , по идеи после верификации приложения фейсбуком
      //должен исчезнуть , но это не точно , пока других способов решения нет
      window.FB.login(
        function(response) {
          if (response.authResponse) {
            //Вылетае ворнинг в консоль потому что пока приложение работает не через https
            window.FB.api(`/me`, 'GET', { fields: 'email,first_name' }, res =>
              saveUser(res)
            )
          } else {
            console.log('User cancelled login or did not fully authorize.') // eslint-disable-line no-console
          }
        },
        { scope: 'email' }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  font-family: 'Roboto', sans-serif;
  text-align: left;
}
.social-block {
  button {
    background: rgba(81, 142, 248, 0.1);
    border-radius: 8px;
  }
}
.row {
  margin-right: 0;
  margin-left: 0;
}
@media (min-width: 992px) {
  .login-page {
    background: url('../assets/login-page/login-bg.svg') center top/cover
      no-repeat;
  }
  .login-form {
    background: #ffffff;
    box-shadow: 9px 9px 40px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
  }
}
@media (min-width: 1500px) {
  .login-form {
    /deep/ .logo {
      margin-top: 2rem !important;
    }
    /deep/ .register {
      margin-bottom: 1rem !important;
    }
  }
}
</style>
